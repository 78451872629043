import React, { useEffect, useState } from "react";
import './header.css'

//icons

import { NavLink } from "react-router-dom";

//images
import main_logo from "../../assets/img/logo-new.png";
import students from "../../assets/img/students.png";
import teacher from "../../assets/img/teacher.png";
import * as Constant from '../Constant'
import { FaPhoneVolume, FaLinkedinIn, FaWhatsapp } from "react-icons/fa"
import { SiFacebook } from "react-icons/si"
import { BsInstagram } from "react-icons/bs"
import { BiChevronDown,BiChevronRight } from "react-icons/bi"
import ReactGA from "react-ga";

function Header() {
  const [width, setWidth] = useState(window.innerWidth);
  const [firstname, setFirstName] = useState();
  const [lastname, setLastName] = useState();
  const [role, setRole] = useState();
  const [subject, setSubject] = useState();
  const [subject_id, setSubId] = useState();
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [level_id, setLevelId] = useState();
  const [level_name, setLevelName] = useState();
  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedSubjectname, setSelectedSubjectName] = useState();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const setGA = () => {
    console.log("setga");
    ReactGA.initialize("UA-273814663-1", { debug: true }); //New
    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  const isMobile = width <= 768;

  const [state, setState] = useState({
    dropdown: false,
    showmenu: false,
    showsubject: false,
    showservices: false,
    showabout: false,
    showmaths: false,
    showscience: false,
    showcomputing: false,
    SubjectList: [],


  })
  const getUserId = localStorage.getItem('roleId');

  const showList = () => {
    if (state.dropdown === true) {
      setState({ ...state, dropdown: false })
    } else {
      setState({ ...state, dropdown: true })
    }

  }

  const getSubject = async () => {
    await fetch(Constant.getSubject, {
      method: "GET"
    })
      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          setState({
            ...state,
            SubjectList: data.data
          })
        } else {
          console.log('subject not available')
        }
      })
  }

  useEffect(() => {
    getStudentProfile();
    getSubject();
    setGA();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])

  const getStudentProfile = async () => {

    var userId = localStorage.getItem('userId');

    await fetch(Constant.getUserProfile + '/' + userId, {
      method: "GET"
    })
      .then(Response => Response.json())
      .then(data => {
        console.log('trtrtrtr', data.data)
        setLastName(data.data.last_name)
        setFirstName(data.data.first_name)
        setRole(data.data.role)
        setSubject(data.data.subject)
        setSubId(data.data.subject_id)
        setLevelId(data.data.sub_level_id)
        setLevelName(data.data.level_name)
        if (data.data.selectedCoursesSubject.length === 0) {
          setSelectedSubject(data.data.subject_id)
          setSelectedSubjectName(data.data.subject)
        } else {
          setSelectedCourses(data.data.selectedCoursesSubject)
        }

      })
  }

  const LogOut = () => {
    localStorage.clear();
    window.location.href = Constant.BaseUrl + "login"
  }

  const showMenu = () => {
    if (state.showmenu === false) {
      setState({
        ...state, showmenu: true
      })
    } else {
      setState({
        ...state, showmenu: false
      })
    }
  }
  const showSubject = () => {
    if (state.showsubject === false) {
      setState({
        ...state, showsubject: true
      })
    } else {
      setState({
        ...state, showsubject: false
      })
    }
  }
  const showServices = () => {
    console.log(state.showservices)
    if (state.showservices === false) {
      setState({
        ...state, showservices: true
      })
    } else {
      setState({
        ...state, showservices: false
      })
    }
  }
  const showAbout = () => {
    console.log(state.showabout)
    if (state.showabout === false) {
      setState({
        ...state, showabout: true
      })
    } else {
      setState({
        ...state, showabout: false
      })
    }
  }

  const storeId = (value) => {
    try {
      localStorage.setItem("subjectId", value);
      console.log('ok')
    } catch (e) { }
  }

  function loadSubject(value) {
    if (value == 'exam papers') {
      value = value
        .toLowerCase()
        .replace(/\s+/g, '_')
        .replace(/[^a-zA-Z0-9_]/g, '');
    }

    // if (getUserId === null) {
    //   window.location.href = Constant.BaseUrl + 'login'
    // } else {
    window.location.href = Constant.BaseUrl + value;

    // }
  }

  return (
    <header className="atf-site-header atf-style1 atf-sticky-header">
      <div className="atf-top-header" style={{ height: "40px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-7">
              <div className="atf-top-header-in">
                <ul className="atf-top-header-list">
                  <li>
                    <a href="tel:+447776757225" style={{ marginLeft: 40 }} className="text-left">
                      <FaPhoneVolume style={{ marginLeft: '-30px' }} />
                      <FaWhatsapp style={{ marginLeft: '5px', marginRight: '5px' }} /><text >+447776757225</text>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="atf-top-social">
                <a
                  target="_blank"
                  href="https://www.facebook.com/educationcapsule"
                  className="icon m-1"
                  style={{ color: '#fff' }}
                >
                  {" "}
                  <SiFacebook />{" "}
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/educationcapsule/"
                  className="icon m-1"
                  style={{ color: '#fff' }}
                >
                  {" "}
                  <FaLinkedinIn className="ml-3 mr-3" />{" "}
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/educationcapsule1/"
                  className="icon m-1"
                  style={{ color: '#fff' }}
                >
                  <BsInstagram />
                </a>
              </div>
            </div>
            {
              getUserId === null ?
                <div className="col-lg-2 col-md-2">
                  <div className="login-details">
                    <ul>
                      <li>
                        <a href="/login">Login</a>
                      </li>
                      <li>
                        <a href="/register">Join Now</a>
                      </li>
                    </ul>
                  </div>
                </div> :
                <div className="col-lg-2 col-md-2">
                  <div className="login-details">
                    <ul>
                      <li>
                        <a href="#" onClick={LogOut}>Logout</a>
                      </li>
                    </ul>
                  </div>
                </div>
            }

          </div>
        </div>
      </div>
      <div className="atf-main-header">
        <div className="container">
          <div className="atf-main-header-in">
            <div className="atf-main-header-left">
              <NavLink to="/" className="atf-site-branding atf-white-logo">
                <img style={{ cursor: 'pointer' }} src={main_logo} alt="Logo" />
              </NavLink>
            </div>
            {
              getUserId === null ?
                <div className="atf-main-header-right">
                  {
                    isMobile === true ?
                      <div class="atf-nav">
                        {
                          state.showmenu === true ? <ul class="atf-nav-list atf-onepage-nav" id="navList" style={{ display: 'block' }}>
                            <li class="list-nav-home"><a href="/" class="home-page">Home</a></li>
                            {/* <li class="menu-item-has-children list-nav-home"><a href="#" >Subjects<i class="fa fa-chevron-down ml-2"></i></a>
                              {
                                state.showsubject === true ?
                                  <ul style={{ display: 'block' }}>
                                    {
                                      state.SubjectList.map((item, i) => (
                                        <li key={i} value={item.id} onClick={() => storeId(item.id)} className="menu-item-has-children list-nav-home left-class"><a href="#" onClick={() => loadSubject(item.title.toLowerCase())}>{item.title}</a>
                                        </li>
                                      ))
                                    }
                                  </ul>
                                  : ''
                              }
                              <span class={state.showsubject === true ? 'atf-menu-dropdown-toggle active' : 'atf-menu-dropdown-toggle'} onClick={() => showSubject()}></span>
                            </li> */}
                            <li class="menu-item-has-children list-nav-home"><a href="#" id="services">For students</a>
                              {
                                state.showservices === true ?
                                  <ul style={{ display: 'block' }}>
                                    <li><a href="/exam-prep">Exam Preparation</a></li>
                                    <li><a href="/curriculm-page">Curriculum Help</a></li>
                                    <li><a href="/homework-help" class="">Home Work Help</a></li>
                                    <li><a href="/book-online-expert" class="" id="book_free_demo">Book Free Lesson</a></li>
                                    <li><a href="/educational-advice">Educational Advice</a></li>
                                    <li><a href="/special-education">Special Education</a></li>
                                  </ul> : ''
                              }

                              <span class={state.showservices === true ? 'atf-menu-dropdown-toggle active' : 'atf-menu-dropdown-toggle'} onClick={() => showServices()}></span></li>
                            <li className="left-class">
                              <a href="/course" > Training Program</a>
                            </li>
                            <li className="left-class">
                              <a href="/exam-preparation" >Exam Preparation</a>
                            </li>

                            
                            <li class="menu-item-has-children list-nav-home"><a href="#">About<i class="fa fa-chevron-down ml-2"></i></a>
                              {
                                state.showabout === true ? <ul style={{ display: 'block' }}>
                                  <li><a href="/how-we-work" class="">How We Work</a></li>
                                  <li><a href="/our-approach">Our Approach</a></li>
                                  <li><a href="/testimonials">Testimonials</a></li>
                                  <li><a href="/membership">Membership</a></li>
                                </ul> : ''
                              }
                              <span class={state.showabout === true ? 'atf-menu-dropdown-toggle active' : 'atf-menu-dropdown-toggle'} onClick={() => showAbout()}></span></li>

                            <li><a href="/book-a-tutor" class="student-reg" id="request_a_tutor">Request A Tutor </a></li>

                            <li><a href="/blogs" class="student-reg" >Blog</a></li>
                            <li class="list-nav-home"><a href="/contact-us" class="" id="contact_us">Contact Us</a></li>
                            <li class="list-nav-home"><a href="/login" class="" id="contact_us">Login</a></li>

                          </ul> : ''
                        }

                        <div className="login_signup right">
                        </div>
                        <span className={state.showmenu === true ? "atf-menu-toggle atf-toggle-active" : "atf-menu-toggle"} onClick={() => showMenu()}><span></span></span>
                      </div> :
                      <div className="atf-nav" id="resNav">
                        <ul className="atf-nav-list atf-onepage-nav" id="navList">
                          <li className="list-nav-home">
                            <NavLink to="/" className="home-page">
                              Home
                            </NavLink>
                          </li>
                          {/* <li className="menu-item-has-children list-nav-home"><a href="#" >Subjects<BiChevronDown style={{ fontSize: 25 }} /></a>
                            <ul >
                              {
                                state.SubjectList.map((item, i) => (
                                  <li key={i} value={item.id} onClick={() => storeId(item.id)} className="menu-item-has-children list-nav-home left-class"><a href="#" onClick={() => loadSubject(item.title.toLowerCase())}>{item.title}</a>
                                  </li>
                                ))
                              }
                            </ul>
                          </li> */}
                           <li className="menu-item-has-children list-nav-home">
                            <a href="#" id="services">
                              For students<BiChevronDown style={{ fontSize: 25 }} />
                            </a>
                            <ul>
                            <li className="left-class">
                                <NavLink to="/one-to-one" >One to one tutoring</NavLink>
                              </li>
                              <li className="left-class">
                                <NavLink to="/exam-prep" >Exam Preparation</NavLink>
                              </li>
                              
                              <li className="left-class">
                                <NavLink to="/homework-help" >
                                  Homework Help
                                </NavLink>
                              </li>
                              <li className="left-class"><NavLink to="/home-schooling" >Home schooling</NavLink></li>
                              <li className="left-class"><NavLink to="/curriculm-page" >Curriculum help</NavLink></li>
                            </ul>
                          </li>
                          <li className="menu-item-has-children list-nav-home relative group">
                              <a href="/course" id="services" className="flex items-center">
                                Training Program
                              </a>
                          </li>

                          {/* <li className="left-class">
                            <NavLink to="/exam-preparation" >Exam Preparation</NavLink>
                          </li> */}
                         
                          {/* <li className="menu-item-has-children list-nav-home"><a href="#">About<BiChevronDown style={{ fontSize: 25 }} /></a>
                            <ul>
                              <li className="left-class"><NavLink to="/how-we-work" >How We Work</NavLink></li>
                              <li className="left-class"><NavLink to="/our-approach">Our Approach</NavLink></li>
                              <li className="left-class"><NavLink to="/testimonials">Testimonials</NavLink></li>
                              <li className="left-class"><NavLink to="/membership">Membership</NavLink></li>
                            </ul>
                          </li> */}
                          <li className="list-nav-home">
                            <a href="/book-a-tutor" className="home-page">
                              Hire A Tutor
                            </a>
                          </li>
                          {/* <li className="list-nav-home">
                            <a href="/blogs" className="home-page">
                             Blog
                            </a>
                          </li> */}
                            <li className="list-nav-home">
                            <a href="/how-we-work" className="home-page">
                              How we work
                            </a>
                          </li>
                          <li className="list-nav-home">
                            <a href="/tutor-register" className="home-page">
                              Become A Tutor
                            </a>
                          </li>
                          <li className="list-nav-home">
                            <a href="/testimonials" className="home-page">
                              Testimonials
                            </a>
                          </li>
                          {/* <li className="left-class">
                            <NavLink to="/membership" >Subscribe</NavLink>
                          </li> */}
                          <li className="list-nav-home">
                            <a href="/contact-us" className="home-page">
                              Contact Us
                            </a>
                          </li>
                        </ul>
                      </div>
                  }

                </div> :
                <div className="atf-main-header-right">
                  <div className="atf-nav" >
                    {getUserId == 3 ?
                      <ul className="atf-nav-list atf-onepage-nav" id="navList">
                        {/* <li class="list-nav-home"><NavLink to="/wallet" class="home-page" style={{ marginRight: '15px', color: '#fff', fontSize: '15px', fontWeight: '600' }}>Wallet</NavLink></li> */}
                        <li className="list-nav-home"><NavLink to="/student-dashboard" className="home-page" style={{ color: '#fff', fontSize: '15px', fontWeight: '600' }}>Dashboard</NavLink></li>
                        <li className="left-class"><NavLink to="/exam-preparation" >Exam Preparation</NavLink></li>
                        {/* <li class="list-nav-home"><NavLink to="/book-session" class="home-page" style={{ marginRight: '15px', color: '#fff', fontSize: '15px', fontWeight: '600' }}>Book lessons</NavLink></li> */}
                        <li className="menu-item-has-children list-nav-home"><a href="/#" style={{ color: '#fff', fontSize: '15px', fontWeight: '600' }} >Subjects<BiChevronDown style={{ fontSize: 25 }} /></a>
                          <ul>
                            <li class="menu-item-has-children list-nav-home">
                              {
                                selectedCourses === undefined ? <NavLink to={"subject-details/" + level_id}>{subject} ({level_name})<i class="fa fa-chevron-down ml-2"></i></NavLink> :
                                  <div>
                                    {
                                      selectedCourses.length === 0 ? <NavLink onClick={() => storeId(selectedSubject)} to={selectedSubjectname}>{selectedSubjectname} <i class="fa fa-chevron-down ml-2"></i></NavLink> : <>  {
                                        selectedCourses.map((item, i) => (
                                          <NavLink onClick={() => storeId(item.id)} to={item.title}>{item.title} <i class="fa fa-chevron-down ml-2"></i></NavLink>
                                        ))
                                      }</>
                                    }

                                  </div>

                              }
                            </li>
                          </ul>
                        </li>
                        <li className="list-nav-home mt-3" style={{ color: '#fff', fontSize: '15px', fontWeight: '600' }}>{firstname}&nbsp;&nbsp;{lastname}</li>
                      </ul>
                      :
                      <ul className="atf-nav-list atf-onepage-nav" id="navList">
                        <li className="menu-item-has-children list-nav-home"><a href="/#" style={{ color: '#fff', fontSize: '15px', fontWeight: '600' }} >Subjects<BiChevronDown style={{ fontSize: 25 }} /></a>
                          <ul>
                            {
                              state.SubjectList.map((item, i) => (
                                <li key={i} value={item.id} onClick={() => storeId(item.id)} className="menu-item-has-children list-nav-home left-class"><NavLink to={item.title.toLowerCase()}>{item.title}</NavLink>
                                </li>
                              ))
                            }
                          </ul>
                        </li>
                        <li className="list-nav-home"><NavLink to="/tutor-dashboard" className="home-page" style={{ color: '#fff', fontSize: '15px', fontWeight: '600' }}>Dashboard</NavLink></li></ul>}
                    <div className="login_signup right">
                      <div className="user_dropdownwarp">
                        <a href="#" onClick={() => showList()} className="profile_pic">{getUserId == 3 ? <img src={students} alt="user" /> : <img src={teacher} alt="teacher" />}</a>
                        {
                          state.dropdown === true ?
                            <div className="user_dropdown" >
                              <ul>
                                {
                                  getUserId == 3 ?
                                    <li>
                                      <NavLink to="/my-profile"><p style={{ color: '#ffa500', fontWeight: '600' }} class="mt-1">{firstname}&nbsp; &nbsp;{lastname}</p>
                                        <span style={{ marginTop: '5px', fontWeight: '700' }}>{role}</span>
                                      </NavLink>
                                    </li>
                                    :
                                    <li>
                                      <NavLink to="/tutor-profile"><p style={{ color: '#ffa500', fontWeight: '600', fontSize: 13 }} class="mt-1">{firstname}&nbsp; &nbsp;{lastname}</p>
                                        <span style={{ marginTop: '5px', fontWeight: '700' }}>{role}</span>
                                      </NavLink>
                                    </li>
                                }

                                <li><a href="#" onClick={() => LogOut()}>Log out</a></li>
                              </ul>
                            </div> : ''
                        }

                      </div>
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </header >
  );
}

export default Header;
