import React from "react"

import user from '../../assets/img/subjects/person.png'
import exploremore from '../../assets/img/subjects/exploremore.png'
import clock from '../../assets/img/subjects/clock.png'
import science_level from '../../assets/img/subjects/science_level.png'
import book from '../../assets/img/subjects/book.png'
import note from '../../assets/img/subjects/note.png'
import science_round from '../../assets/img/subjects/science_round.png'
import { NavLink } from "react-router-dom";
import './about.css'
import work_one from '../../assets/img/about/work_one.png'
import work_two from '../../assets/img/about/work_two.png'
import work_three from '../../assets/img/about/work_three.png'

import one from '../../assets/img/about/one.png'
import two from '../../assets/img/about/two.png'
import three from '../../assets/img/about/three.png'
import four from '../../assets/img/about/four.png'
import five from '../../assets/img/about/five.png'

import icon_1 from '../../assets/img/about/Icon.png'
import icon_2 from '../../assets/img/about/icon_2.png'
import icon_3 from '../../assets/img/about/icon_3.png'
import icon_4 from '../../assets/img/about/icon_4.png'
import icon_5 from '../../assets/img/about/icon_5.png'


import six from '../../assets/img/about/six.png'
import seven from '../../assets/img/about/seven.png'
import * as Constant from '../Constant'
import LazyVideo from "../LazyVideo2"


class HowWeWork extends React.Component {

    constructor() {
        super();

        this.state = {
            paperList: [],
            isLoading: false
        }
    }

    componentDidMount = () => {
        this.getPapers();
    }

    showPaper = (id, totalQuestion) => {
        if (totalQuestion === 0) {
            const url = Constant.BaseUrl + 'contact-us'
            window.location.href = url;
        } else {
            const url = Constant.BaseUrl + 'exam_papers/1/' + id;
            // window.open(url, '_blank');
            window.location.href = url;
        }

    }

    getPapers = async () => {
        this.setState({ isLoading: true })


        await fetch(Constant.getPapers + '/' + 1, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                this.setState({ paperList: data.data, isLoading: false })
            })
    }


    render() {
        return (
            <>
                <div className="mt-5" style={{ backgroundColor: '#CC3391' }}>
                    <div className="row pb-5">
                        <div className="col-md-7 col-sm-6 mb-5 pb-5" style={{ display: 'flex', justifyContent: 'center' }}>
                            <div>
                                <h1 className="head_Text worktodoline" style={{ marginTop: 100 }}>What is<br></br>Education Capsule?</h1>
                                <p className="mt-5 sub_text" style={{ textAlign: 'justify', width: 660 }}>Education Capsule is an online tutoring platform with qualified and experienced tutors who help the child to build confidence with face-to-face personalized tutoring sessions for the child.</p>
                            </div>
                        </div>
                        <div className="col-md-5 mt-5"> 
                            <LazyVideo />
                        </div>
                    </div>
                </div>
                <p className="text-center mt-5 head_font">How it works</p>

                <div className="row mt-5">
                    <div className="col-md-3" style={{ paddingRight: 0 }}>
                        <img src={work_one} alt="works"/>
                        <img src={work_two} alt="works" />
                        <img src={work_three} alt="works"/>
                    </div>
                    <div className="col-md-9 bg-black" style={{ border: 'none' }}>
                        <div>
                            <img src={one} alt="works" className="padding50" />
                            <img src={icon_1}  alt="works"/>
                            <span className="sub_text padding20" >Sign up</span>
                        </div>
                        <div>
                            <img src={two} alt="works" className="padding50" />
                            <img src={icon_2}  alt="works"/>

                            <span className="sub_text padding20">Book your session</span>

                        </div>
                        <div>
                            <img src={three} alt="works" className="padding50" />
                            <img src={icon_3} alt="works"/>

                            <span className="sub_text padding20">Pre-Assessment</span>

                        </div>
                        <div>
                            <img src={four} alt="works" className="padding50" />
                            <img src={icon_4} alt="works" />

                            <span className="sub_text padding20">One To One Tutoring</span>

                        </div>
                        <div>
                            <img src={five} alt="works" className="padding50" />
                            <img src={icon_5} alt="works" />

                            <span className="sub_text padding20">Personalized Session</span>

                        </div>
                        <div>
                            <img src={six} alt="works" className="padding50" />
                            <img src={icon_3} alt="works"/>

                            <span className="sub_text padding20">Practice Test Material</span>

                        </div>
                        <div>
                            <img src={seven} alt="works" className="padding50" />
                            <img src={icon_5} alt="works" />

                            <span className="sub_text padding20">Self Evaluation</span>

                        </div>
                    </div>
                </div>

                <div className=" whats_include">
                    <p className="text-center mt-5 head_font">Our Expert Tutors</p>
                    <div className="row mt-5 ">
                        <div className="col-md-2"></div>
                        <div className="col-md-2 imagestyle">
                            <div className="include_div sciencemb">
                                <div className="imgcenter mt-4">
                                    <img src={user} alt="user" style={{ width: 125 }} />
                                </div>
                                <p className="include_sub_text mt-3 mb-3">One To One<br></br>
                                    Teaching</p>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-2 imagestyle">
                            <div className="include_div sciencemb">
                                <div className="imgcenter mt-4">
                                    <img src={clock} alt="clock" style={{ width: 125 }} />
                                </div>
                                <p className="include_sub_text mt-3 mb-3">Flexible Available <br></br>Support</p>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-2 imagestyle">
                            <div className="include_div sciencemb">
                                <div className="imgcenter mt-4">
                                    <img src={science_level} alt="science_level" style={{ width: 125 }} />
                                </div>
                                <p className="include_sub_text mt-3 mb-3">Interactive<br></br>
                                    learning space</p>
                            </div>
                        </div>
                        <div className="col-md-2"></div>

                        <div className="col-md-2"></div>

                        <div className="col-md-2 imagestyle mt-5">
                            <div className="include_div sciencemb">
                                <div className="imgcenter mt-4">
                                    <img src={book} alt="book" style={{ width: 125 }} />
                                </div>
                                <p className="include_sub_text mt-3 mb-3">Covering upto<br></br>
                                    All Levels</p>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-2  imagestyle mt-5">
                            <div className="include_div sciencemb">
                                <div className="imgcenter mt-4">
                                    <img src={note} alt="note" style={{ width: 125 }} />
                                </div>
                                <p className="include_sub_text mt-3 mb-3">Competitive<br></br>
                                    prices</p>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-2  imagestyle mt-5">
                            <div className="include_div sciencemb">
                                <div className="imgcenter mt-4">
                                    <img src={science_round} alt="note" style={{ width: 125 }} />
                                </div>
                                <p className="include_sub_text mt-3 mb-3">Learn anytime,<br></br>
                                    anywhere</p>
                            </div>
                        </div>
                        <div className="col-md-2"></div>

                    </div>
                </div>
                <div className="bg-black mt-5" style={{ border: 'none' }}>
                    <p className="text-center pt-5" style={{ fontWeight: 700, fontSize: 38, color: '#fff' }}>Explore More</p>
                    <div className="row pb-5">
                        <div className="col-md-4 pt-5" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <div >
                                <p className="pt-5 head_Text" >Build your<br></br><br></br> Child’s Future </p>
                                <p className="mt-4" style={{ textAlign: 'justify', color: '#fff', fontSize: 17 }}>We have the best quality online <br></br>tutors who ensure a bright <br></br>future for your child’s learning <br></br>and knowledge development.<br></br> Get your child enrolled with us now.</p>
                            </div>
                        </div>

                        <div className="col-md-4 mt-5">
                            {
                                this.state.paperList.map((item, i) => (
                                    <div key={i} className="mt-3">
                                        <p className="text-center mt-4" onClick={() => this.showPaper(item.id, item.total_question)} style={{ color: '#fff', fontWeight: 600, fontSize: 20, cursor: 'pointer' }} >{item.name}</p>
                                        <hr className="horizontal_line"></hr>
                                    </div>
                                ))
                            }

                            <div className="mt-3 mobile">
                                <NavLink className="btn btn-info student-registration" to="/book-a-tutor" style={{ backgroundColor: '#a32e90', color: '#fff', borderRadius: 30 }}><span>Book free Lesson</span></NavLink>
                            </div>
                        </div>
                        <div className="col-md-4 pt-5" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <div>
                                <img src={exploremore} alt="Exploremore" className="mt-4" style={{ width: 320 }} />
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default HowWeWork