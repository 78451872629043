import React from "react";
import sen from '../assets/img/services/new/sen.png'

class OneToOne extends React.Component {

    render() {
        return (
            <>
                <div style={{ marginTop: '-1px' }}>
                    <section className="service-page-new" >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 col-sm-12 col-lg-7 col-xl-7 col-xs-12">
                                    <h1 className="banner-text1" style={{ textAlign: 'left', marginTop: 55 }}>Why Choose One-to-One Tutoring?</h1>
                                    <p style={{ color: '#fff', textAlign: 'justify', fontSize: 19 }}>At Education Capsule, we specialize in one-to-one tutoring that tailors education to each student's unique learning style and pace.Experience the advantages of personalized attention and customized lesson plans designed to empower your academic success.
                                    </p>
                                    {/* <a href="/book-a-tutor" className="lending-btn" >Book free Lesson Now</a> */}

                                </div>
                                <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12">
                                    <img src={sen} alt="sen" className="p-2" />
                                </div>

                            </div>
                        </div>
                    </section >
                </div>

                <div className="container one-to-one">
                    <div className="row">
                        <h2 className="text-center mb-4">Why Choose One-to-One Tutoring?</h2>
                        <p><strong>Personalized Attention:</strong> Explain how tutors focus solely on one student, ensuring that teaching styles and pace are perfectly matched to the student’s learning abilities.</p>
                        <p><strong> Flexible Scheduling:</strong> Highlight the flexibility in scheduling sessions that fit the student's and tutor’s availability, making it easier to incorporate tutoring into busy schedules.</p>
                        <p><strong> Targeted Support:</strong> Discuss how tutors can identify specific areas where the student needs help and focus intensively on those aspects, be it complex algebra problems or essay writing skills.</p>
                   
                        <h3> Our Tutors</h3>

                        <p><strong>Qualified Professionals:</strong> Provide information about the qualifications and expertise of the tutors. Emphasize their training and the rigorous selection process they undergo.</p>
                        <p><strong>Matching Process:</strong> Describe how students are matched with the best tutor for their subject needs and learning style, ensuring optimal compatibility and effectiveness.</p>
                        
                        <h3>How Our Sessions Work</h3>

                        <p><strong>Initial Assessment:</strong>  Outline the initial assessment process used to evaluate the student's current proficiency and identify areas for improvement.</p>
                        <p><strong>Customized Learning Plans:</strong>  Detail how personalized learning plans are created based on the assessment findings, tailored to accelerate the student’s progress.</p>
                        <p><strong>Session Structure:</strong>  Explain the typical structure of a tutoring session, including duration, tools used (like interactive software), and methods of feedback.</p>
                        
                        <h3>Success Stories</h3>

                        <p><strong>Testimonials:</strong>  Share testimonials from students and parents who have experienced significant improvements through your tutoring.</p>
                        <p><strong>Achievements:</strong>  Highlight any notable achievements of students, such as improved grades, successful exam results, or admissions to desired schools or programs.</p>
                  

                    </div>
                </div>
                </>
            )
    }
}
export default OneToOne;