import React from "react"

import "./Courses.css";
import { Link } from "react-router-dom";

import * as Constant from '../Constant'


class Course extends React.Component {

    constructor() {
        super();

        this.state = {
            courseList: [],
            isLoading: false
        }
    }

    componentDidMount = () => {
        this.getCourses();
    }

    getCourses = async () => {
        this.setState({ isLoading: true })


        await fetch(Constant.getCourses , {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                this.setState({ courseList: data.data, isLoading: false })
            })
    }


    render() {
        return (
            <>
                <React.Fragment>
                        <section class="ftco-section">
                          <div class="container justify-content-center">
                          <p className="text-center mt-5 head_font">Our Courses</p>
                            <div className="row ms-3">
                            { this.state.courseList.map((item) => {
                            return (
                                <div className="col-md-4">
                                  <article class="card cardquiz mt-5">
                                    <img
                                      className="card__background"
                                      src={item.image}
                                      alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
                                      width="1920"
                                      height="2193"
                                    />
                                    <div class="card__content | flow">
                                      <div class="card__content--container | flow">
                                        <h2 class="card__title h22">{item.course_name}</h2>
                                        <p class="card__description pragefg">
                                        {item.course_title} 
                                        </p>
                                       
                                      </div>
                                      <Link  to={(`/course_details/${item.slug}`)} class="lending-btn">Show Details</Link>
                                    </div>
                                  </article>
                                  </div>
                
                                )
                            })
                          }
                            </div>
                          </div>
                        </section>
                      </React.Fragment>
             
            </>
        )
    }
}

export default Course