import React from "react";

import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";

import harryk from '../../assets/img/about/harryk.jpeg'
import samantha from '../../assets/img/about/samanths.jpeg'
import carl from '../../assets/img/about/carl.png'
import robert from '../../assets/img/about/robert.jpeg'
import scott from '../../assets/img/about/scott.jpg'

class Testimonials extends React.Component {
    render() {
        return (
            <>
                <div className="hii">
                    <section className="sptb" id="exam-help-section">

                        <div className="container">
                            <div className="exam-bannner">
                                <h1> Education Capsule is Loved by Students and Trusted by Parents</h1>
                                <NavLink className="btn btn-info student-registration" to="/book-a-tutor" style={{ backgroundColor: '#a32e90', color: '#fff' }}><span>Book Free Lesson Now!</span></NavLink>
                                {/* <NavLink className="btn btn-info student-registration" to="/student-register" style={{ backgroundColor: '#a32e90', color: '#fff' }}><span>Book Free Lesson Now!</span></NavLink> */}
                            </div>
                        </div>
                    </section>
                </div>

                <section className="testimonial_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="about_content">
                                    <div className="background_layer"></div>
                                    <div className="layer_content">
                                        <div className="section_title">
                                            <h5 className="left-className">Students</h5>
                                            <h2 className="left-className">Testimonials by <strong>Students</strong></h2>
                                            <div className="heading_line"><span></span></div>
                                            <p>Education Capsule aims at making learning fun for students. We make sessions interactive and engaging and students love learning with Education Capsule.</p>
                                        </div>
                                        <NavLink to="/contact-us" style={{ float: 'left' }}>Contact Us<i className="icofont-long-arrow-right"></i></NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="testimonial_box">
                                    <div className="testimonial_container">
                                        <div className="background_layer"></div>
                                        <div className="layer_content">
                                            <div className="testimonial_owlCarousel owl-carousel">
                                                <Carousel>
                                                    <Carousel.Item>
                                                        <div className="testimonials">
                                                            <div className="testimonial_content" style={{ marginLeft: 0 }}>
                                                                <div className="testimonial_caption">
                                                                    <h6>Olivia</h6>
                                                                </div>
                                                                <p>Math classes were excellent. My tutor was kind and polite always. Thank you for helping me.</p>
                                                            </div>
                                                            {/* <div className="images_box">
                                                                <div className="testimonial_img">
                                                                    <img className="img-center" src={olivia} style={{ width: '74%', marginRight: 10 }} alt="images not found" />
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </Carousel.Item>

                                                    <Carousel.Item>
                                                        <div className="testimonials">
                                                            <div className="testimonial_content" style={{ marginLeft: 0 }}>
                                                                <div className="testimonial_caption">
                                                                    <h6>Amber Year 6</h6>
                                                                </div>
                                                                <p>The best part of my sessions was that my tutor always tried to explain the topic by using real-world examples, which helped me to understand everything easily.</p>
                                                            </div>
                                                            {/* <div className="images_box">
                                                                <div className="testimonial_img">
                                                                    <img className="img-center" src={amber} style={{ width: '74%', marginRight: 10 }} alt="images not found" />
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </Carousel.Item>

                                                    <Carousel.Item>
                                                        <div className="testimonials">
                                                            <div className="testimonial_content" style={{ marginLeft: 0 }}>
                                                                <div className="testimonial_caption">
                                                                    <h6>Angelina Year 5</h6>
                                                                </div>
                                                                <p>My teacher cleared all my doubts in no time!!!!</p>
                                                            </div>
                                                            {/* <div className="images_box">
                                                                <div className="testimonial_img">
                                                                    <img className="img-center" src={angelina} style={{ width: '74%', marginRight: 10 }} alt="images not found" />
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </Carousel.Item>

                                                    <Carousel.Item>
                                                        <div className="testimonials">
                                                            <div className="testimonial_content" style={{ marginLeft: 0 }}>
                                                                <div className="testimonial_caption">
                                                                    <h6>Bella Year 7</h6>
                                                                </div>
                                                                <p>Education capsule has wowed me with its concept videos. I enjoyed learning here. Many thankssss.</p>
                                                            </div>
                                                            {/* <div className="images_box">
                                                                <div className="testimonial_img">
                                                                    <img className="img-center" src={bella} style={{ width: '74%', marginRight: 10 }} alt="images not found" />
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </Carousel.Item>

                                                    <Carousel.Item>
                                                        <div className="testimonials">
                                                            <div className="testimonial_content" style={{ marginLeft: 0 }}>
                                                                <div className="testimonial_caption">
                                                                    <h6>Alex 11+ Exam Prep</h6>
                                                                </div>
                                                                <p>"Overall, I've enjoyed all of the Education capsule sessions I've done. I'm looking forward to taking more lessons."</p>
                                                            </div>
                                                            {/* <div className="images_box">
                                                                <div className="testimonial_img">
                                                                    <img className="img-center" src={alex} style={{ width: '74%', marginRight: 10 }} alt="images not found" />
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </Carousel.Item>

                                                    <Carousel.Item>
                                                        <div className="testimonials">
                                                            <div className="testimonial_content" style={{ marginLeft: 0 }}>
                                                                <div className="testimonial_caption">
                                                                    <h6>Carol Year 8</h6>
                                                                </div>
                                                                <p>Earlier, I was scared of Maths and now I can solve questions easily. No more maths phobia!!</p>
                                                            </div>
                                                            {/* <div className="images_box">
                                                                <div className="testimonial_img">
                                                                    <img className="img-center" src={carol} style={{ width: '74%', marginRight: 10 }} alt="images not found" />
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </Carousel.Item>

                                                </Carousel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="faq_sec mt-4 mb-5 pt-5 pb-5" style={{ marginLeft: '8%', marginRight: '8%' }}>
                    <div className="row">
                            <div class="elfsight-app-edda347b-b7da-4efa-a9e5-6cffd8b2cf9f" data-elfsight-app-lazy></div>
                    </div>
                </div>

                <section className="testimonial_section newwwwwwww">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="about_content">
                                    <div className="background_layer"></div>
                                    <div className="layer_content">
                                        <div className="section_title">
                                            <h5 className="left-className">Parents</h5>
                                            <h2 className="left-className">Testimonials by <strong>Parents</strong></h2>
                                            <div className="heading_line"><span></span></div>
                                            <p>Education Capsule is an online learning platform which is not only loved by students but also trusted by parents. Parents get to see the progress report of their children on regular basis. Here is what parents say about Education Capsule. </p>
                                        </div>
                                        <NavLink to="/contact-us" style={{ float: 'left', marginBottom: 45 }}>Contact Us<i className="icofont-long-arrow-right"></i></NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="testimonial_box">
                                    <div className="testimonial_container">
                                        <div className="background_layer"></div>
                                        <div className="layer_content">
                                            <div className="testimonial_owlCarousel owl-carousel">

                                                <Carousel>
                                                    <Carousel.Item>
                                                        <div className="testimonials">
                                                            <div className="testimonial_content">
                                                                <div className="testimonial_caption">
                                                                    <h6>Harry K</h6>
                                                                </div>
                                                                <p>Well-experienced tutors having in-depth subject knowledge.  Thank you for your support.</p>
                                                            </div>
                                                            <div className="images_box">
                                                                <div className="testimonial_img">
                                                                    <img className="img-center" style={{ marginRight: 300 }} src={harryk} alt="images not found" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Carousel.Item>
                                                    <Carousel.Item>
                                                        <div className="testimonials">
                                                            <div className="testimonial_content">
                                                                <div className="testimonial_caption">
                                                                    <h6>Samantha Willson</h6>
                                                                </div>
                                                                <p>Customised sessions not be missed at all!</p>
                                                            </div>
                                                            <div className="images_box">
                                                                <div className="testimonial_img">
                                                                    <img className="img-center" style={{ marginRight: 350 }} src={samantha} alt="images not found" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Carousel.Item>
                                                    <Carousel.Item>
                                                        <div className="testimonials">
                                                            <div className="testimonial_content">
                                                                <div className="testimonial_caption">
                                                                    <h6>Robert Allen. </h6>
                                                                </div>
                                                                <p>Education capsule helped Zian to prepare well for the 7+ exam. Very happy with the tutor. </p>
                                                            </div>
                                                            <div className="images_box">
                                                                <div className="testimonial_img" >
                                                                    <img className="img-center" style={{ marginRight: 350 }} src={robert} alt="images not found" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Carousel.Item>
                                                    <Carousel.Item>
                                                        <div className="testimonials">
                                                            <div className="testimonial_content">
                                                                <div className="testimonial_caption">
                                                                    <h6>Carl and Jack</h6>
                                                                </div>
                                                                <p>Noah loved his sessions here. He eagerly waits for his sessions every week. </p>
                                                            </div>
                                                            <div className="images_box">
                                                                <div className="testimonial_img">
                                                                    <img className="img-center" style={{ marginRight: 350 }} src={carl} alt="images not found" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Carousel.Item>
                                                    <Carousel.Item>
                                                        <div className="testimonials">
                                                            <div className="testimonial_content">
                                                                <div className="testimonial_caption">
                                                                    <h6>Scott, father of Betley(year 6)</h6>
                                                                </div>
                                                                <p>The most beautiful thing about Education capsule is that my kid is always curious to attend the Education capsule online classes. She feels happy while attending the classes.</p>
                                                            </div>
                                                            <div className="images_box">
                                                                <div className="testimonial_img">
                                                                    <img className="img-center" style={{ marginRight: 350 }} src={scott} alt="images not found" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Carousel.Item>
                                                </Carousel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}

export default Testimonials