import React from "react";

import * as Constant from '../Constant'
import ClipLoader from "react-spinners/ClipLoader";
import eduadvice from '../../assets/img/services/new/eduadvice.png'
import { ToastContainer, toast } from "react-toastify";


class EducationalAdvice extends React.Component {
    constructor() {
        super();
        this.state = {
            CountryList: [],
            country_id: '',
            name: '',
            email: '',
            phone: '',

            isLoading: false,

            nameError: null,
            emailError: null,
            phoneError: null,
        }

        this.handleChangeCountry = this.handleChangeCountry.bind(this);
    }

    handleChangeCountry(e) {
        console.log("country_id Selected!!", e.target.value);
        this.setState({ country_id: e.target.value });
        // console.log(this.state.country_id)
    }

    componentDidMount = () => {
        this.getCountry();
    }

    onSubmit = () => {
        if (this.validate()) {
            this.setState({
                nameError: null,
                emailError: null,
                phoneError: null,
            })
            this.onEnrollDemo();
        }
    }

    validate = () => {
        let nameError = '';
        let emailError = '';
        let phoneError = '';


        if (!this.state.name) {
            nameError = "Please Enter Name"
        }

        var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
        if (!this.state.email) {
            emailError = "The Email Address cannot be empty.";
        } else {
            if (!reg.test(this.state.email)) {
                emailError = "Please enter a valid Email Address.";
            }
        }

        if (!this.state.phone) {
            phoneError = "Please Enter Contact Number"
        }

        if (nameError || emailError || phoneError) {
            this.setState({
                nameError,
                emailError,
                phoneError,
            });


            return false;
        }

        console.log('name', this.state.name)
        console.log('phone', this.state.phone)
        console.log('email', this.state.email)


        return true;
    }


    onEnrollDemo = async () => {
        this.setState({ isLoading: true })

        const formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("email", this.state.email);
        formData.append("phone", this.state.phone);
        formData.append("country_id", this.state.country_id);

        await fetch(Constant.FreeDemo, {
            method: 'POST',
            body: formData,
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({ isLoading: false })
                    this.setState({
                        name: '',
                        email: '',
                        phone: ''
                    })
                    toast.success('Your request forwarded successfully..', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    this.setState({ isLoading: false })
                    console.log(data.message)
                }
            })
    }


    getCountry = async () => {
        await fetch(Constant.getCountry, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    // console.log(data.data)
                    this.setState({ CountryList: data.data })
                }
            })
    }

    handleChangeLastName = event => {
        const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
        this.setState({ name: result })
    };
    handleChangePhone = event => {
        const result = event.target.value.replace(/[^0-9]/gi, '');
        this.setState({ phone: result })
    };

    render() {
        return (
            <>
                <div style={{ marginTop: '-1px' }}>
                    <section className="service-page-new" >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 col-sm-12 col-lg-7 col-xl-7 col-xs-12">
                                    <h1 className="banner-text1" style={{ textAlign: 'left', marginTop: 60, fontSize: 50 }}>Looking for<br></br> some educational<br></br>advice?</h1>
                                    <p style={{ color: '#fff', textAlign: 'justify', fontSize: 20 }}>Our advisors provide education advice for students<br></br>of different age groups and all streams. </p>
                                    <a href="/book-a-tutor" className="lending-btn" >Book free Lesson Now</a>
                                </div>
                                <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12">
                                    <img src={eduadvice} className="p-2" alt="Education Advice" />
                                </div>

                            </div>
                        </div>
                    </section >
                    <section className="sptb" style={{ marginTop: '-265px' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
                                    <p class="textjustify block_font">Families that have recently changed schools, moved to a new location or migrated to the UK may find it difficult to comprehend the UK Educational System. Our education consultants are experts at assisting families in better understanding the UK educational system and assisting their children in academic success. Our advisors provide education advice for students of different age groups and all streams.</p>
                                    <p className="mt-4 textjustify block_font" > We collaborate closely with families to develop individualized education programs and provide academic and pastoral assistance to students. Our academic advisors can also collaborate with schools or daycare providers to develop comprehensive education plans for students. Education Consultants play a significant and unique role in the education system and can have a favourable and vital impact on the students involved with them.</p>
                                    <p className="mt-4 textjustify block_font" > Education Capsule provides educational advice for students from elementary school through college which could positively influence the students and assist them in obtaining the most benefit from educational and social experiences. Our Education Consultants understand and answer the wants and needs of the students and meet the expectations of students’ parents proactively. The students migrating to novel places can reduce their burden by collaborating and signing up at Education Capsule and obtaining the most efficient advice on their educational journey and growth. By understanding the student's needs, we make every initiative to guide and direct the student towards the best and most efficient educational system which would help in his personal as well as professional growth.</p>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-lg-8 col-md-8 col-xl-8 col-sm-12 col-xs-12">
                                    <div className="texxt-centerrr">
                                        <div className="exam-bannner">
                                            <p className="center-className textjustify block_font" >  At Education Capsule, we offer assistance and guidance to students in making appropriate choices in their studies, majorly emphasizing career aspirations, educational plans, stream choices as well as a selection of schools according to their interests and preferences. We have professional education advisors that make use of expertise and experience to direct the children to choose the best options for meeting their future and present career goals while keeping in mind their financial and academic levels. After completely understanding the students’ background, we help them choose the best institution or course that suits their requirements. Thus, helping the children who are facing difficulties and challenges in coping with moving to another school system is our main focus. Because we believe that undertaking wrong decisions can result in destructive and life-long consequences in the student’s life.
                                            </p>
                                        </div>
                                    </div>
                                    <ToastContainer />
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-4 col-xs-12 col-sm-12">
                                    <div className="learning-form" style={{ paddingBottom: '10px' }}>
                                        <h1 style={{ color: '#fff' }}>Get the advice you need</h1>
                                        <p>From an export</p>
                                        <div className="slider-form-outer">
                                            <div className="form-group">
                                                <input name="name" autoComplete="on" value={this.state.name} onChange={this.handleChangeLastName} type="text" className="form-control" placeholder="Enter Your Name" id="name" />
                                                {!!this.state.nameError && (
                                                    <text style={{ color: "black", marginLeft: "10px" }}>
                                                        {this.state.nameError}
                                                    </text>
                                                )}
                                                <input name="phone" autoComplete="on" value={this.state.phone} onChange={this.handleChangePhone} type="text" className="form-control" placeholder="Enter Your Phone Number" id="phone" minLength="10" maxLength="10" />
                                                {!!this.state.phoneError && (
                                                    <text style={{ color: "black", marginLeft: "10px" }}>
                                                        {this.state.phoneError}
                                                    </text>
                                                )}

                                                <input name="email" value={this.state.email} onChange={(event) =>
                                                    this.setState({ email: event.target.value })
                                                } type="email" autoComplete="on" className="form-control" placeholder="Email Address" id="email" />
                                                {!!this.state.emailError && (
                                                    <text style={{ color: "black", marginLeft: "10px" }}>
                                                        {this.state.emailError}
                                                    </text>
                                                )}
                                                <select name="country_id" id="country_id" value={this.state.country_id} onChange={this.handleChangeCountry} className="form-control country_dropdown">
                                                    <option value="">Select Country</option>
                                                    {
                                                        this.state.CountryList.map((item, i) => (
                                                            <option key={i} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                                <div className="row mt-2">
                                                    <div className="col-sm-6">
                                                    </div>
                                                    <div className="col-sm-6 " style={{ marginTop: '-12px' }}>
                                                        <button
                                                            type="submit"
                                                            name="submit"
                                                            onClick={() => this.onSubmit()}
                                                            class="btnRegister enrolldemo"
                                                        >

                                                            {this.state.isLoading ? (
                                                                <ClipLoader
                                                                    type="TailSpin"
                                                                    color="#ffffff"
                                                                    height="20"
                                                                    width="20"
                                                                />
                                                            ) : (
                                                                "Hire a counselor"
                                                            )}

                                                        </button>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </>

        )
    }
}

export default EducationalAdvice