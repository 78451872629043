import React from 'react';
import './footer.css'

import { NavLink } from 'react-router-dom';
import { FaLinkedinIn, FaChevronRight } from "react-icons/fa"
import { SiFacebook } from "react-icons/si"
import { BsInstagram } from "react-icons/bs"

import gmap from '../../assets/img/newhomepage/gmap.png'

function Footer() {
    return (
        <footer id="footer" className="atf-footer-area mt-5">
            <div className="atf-section-padding pb-0">
                <div className="container">
                    <div className="atf-footer-top mt-5">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-12 atf-footer-link">
                                <div className="atf-footer-box text-left" style={{ marginRight: 0 }}>
                                    <h5>About</h5>
                                    <p className="mt-3 pr-3 text-justify"> Education Capsule is an online tutoring platform with qualified tutors. We provide One-to-One classes, round the clock. Students can learn from the safety of their home, at any convenient time, without any hassles of travel.</p>
                                    <div className="atf-footer-social-icon mt-3 text-left" style={{ display: 'flex' }}>
                                        <a href="https://www.facebook.com/educationcapsule" target="_blank" className="icon footericon m-2" style={{ color: '#fff' }}> <SiFacebook /></a>
                                        <a href="https://www.linkedin.com/in/educationcapsule/" target="_blank" className="icon footericon m-2" style={{ color: '#fff' }}> <FaLinkedinIn /> </a>
                                        <a href="https://www.instagram.com/educationcapsule1/" target="_blank" className="icon footericon m-2" style={{ color: '#fff' }}><BsInstagram /></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2 col-12 atf-footer-link text-left" style={{ marginRight: 0 }}>
                                <h5>Quick Links</h5>
                                <ul className="atf-list-menu">
                                <li> <a href="/blogs"><FaChevronRight className="mr-2" style={{ fontSize: '13px' }} /><text style={{ fontSize: '14px' }}>Blogs</text></a></li>
                                    
                                    <li><a href="/contact-us" className="contact_us_form"><FaChevronRight className="mr-2" style={{ fontSize: '13px' }} /><text style={{ fontSize: '14px' }}>Contact Us</text></a></li>
                                    <li><a href="/tutor-register" id="Join_as_Tutor"><FaChevronRight className="mr-2" style={{ fontSize: '13px' }} /><text style={{ fontSize: '14px' }}>Join as Tutor</text></a></li>
                                    <li> <a href="/terms-and-conditions"><FaChevronRight className="mr-2" style={{ fontSize: '13px' }} /><text style={{ fontSize: '14px' }}>Terms & Conditions</text></a></li>
                                   


                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-2 col-12 atf-footer-link text-left" style={{ marginRight: 0 }}>
                                <h5>Resources </h5>
                                <ul className="atf-list-menu">
                                    <li><a href="/curriculm-page"><FaChevronRight className="mr-2" style={{ fontSize: '13px' }} /><text style={{ fontSize: '14px' }}>Curriculum help</text></a></li>
                                    <li> <a href="/privacy-policy"><FaChevronRight className="mr-2" style={{ fontSize: '13px' }} /><text style={{ fontSize: '14px' }}>Our approach</text></a></li>
                                    <li><a href="/whistleblowing-policy"><FaChevronRight className="mr-2" style={{ fontSize: '13px' }} /><text style={{ fontSize: '14px' }}>Educational advice</text></a></li>
                                    <li> <a href="/special-education"><FaChevronRight className="mr-2" style={{ fontSize: '13px' }} /><text style={{ fontSize: '14px' }}>Special education</text></a></li>


                                </ul>
                            </div>

                            <div className="col-lg-2 col-md-2 col-12 atf-footer-link text-left" style={{ marginRight: 0 }}>
                                <h5>Policies </h5>
                                <ul className="atf-list-menu">
                                    <li><a href="/safeguarding-policy"><FaChevronRight className="mr-2" style={{ fontSize: '13px' }} /><text style={{ fontSize: '14px' }}>Safeguarding Policy</text></a></li>
                                    <li><a href="/whistleblowing-policy"><FaChevronRight className="mr-2" style={{ fontSize: '13px' }} /><text style={{ fontSize: '14px' }}>Whistleblowing Policy</text></a></li>
                                    <li> <a href="/privacy-policy"><FaChevronRight className="mr-2" style={{ fontSize: '13px' }} /><text style={{ fontSize: '14px' }}>Privacy Policy</text></a></li>


                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-4 col-12 atf-footer-link text-left" style={{ marginRight: 0 }}>
                                <h5>Office Address</h5>
                                <p style={{ textAlign: 'left' }}><b>Hauser Forum, Charles Babbage Road,<br></br>Cambridge, CB3 0GT</b></p><br></br>
                                <div id="atf-map-area">
                                    <a href='https://maps.app.goo.gl/Vf2emNZGwp23WWhk7' target='_blank' style={{ cursor: 'pointer' }}><img src={gmap} alt="education capsule map" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="atf-footer-boottom text-center mt-4">
                    <div className="row">
                        <div className="col-md-12">
                            <p style={{ textAlign: 'center' }}>Copyright © 2023 Education Capsule All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;