import React from "react";

import computing from '../../assets/img/newhomepage/computing.jpg'
import science from '../../assets/img/newhomepage/science.jpg'
import english from '../../assets/img/newhomepage/eng.jpg'
import math from '../../assets/img/newhomepage/maths.jpg'

import deliver_first from '../../assets/img/services/new/deliver_1.png'
import deliver_second from '../../assets/img/services/new/deliver_2.png'
import deliver_third from '../../assets/img/services/new/deliver_3.png'
import deliver_forth from '../../assets/img/services/new/deliver_4.png'
import deliver_fifth from '../../assets/img/services/new/deliver_5.png'
import at_educationCap from '../../assets/img/services/new/at_education_capsule.png'

import curriculam_img from '../../assets/img/services/new/curriculam_img.png'

class CurriculamPage extends React.Component {
    render() {
        return (
            <>
                <div style={{ marginTop: '-1px' }}>
                    <section className="service-page-new" >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 col-sm-12 col-lg-7 col-xl-7 col-xs-12" style={{ marginTop: '95px' }}>
                                    <h1 style={{ textAlign: 'left', color: '#fff', fontSize: 55 }}>We Provide <br></br> Tailor-made solutions<br></br>for Your Child</h1>

                                    <a href="/book-a-tutor" className="lending-btn" >Book free Lesson Now</a>
                                </div>
                                <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12">
                                    <img src={curriculam_img} className="p-2" alt="curriculam" />
                                </div>

                            </div>
                        </div>
                    </section >

                    <section className="sptb" style={{ marginTop: '-265px' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
                                    <h3 className="mb-2" style={{ textAlign: 'left', fontSize: 30 }}>At Education Capsule</h3>
                                    <p class="textjustify block_font" > We believe every student is different and has their own learning style, preference, and pace. Some students are not comfortable asking questions in a class full of students. In Education Capsule, we offer one-to-one personalized and adaptive sessions for students from Key Stage 1 to 3 to help prepare their curriculum. The tutors in Education Capsule are masters in their subject field and are committed to offering creative and flexible lessons from the curriculum, specifically in the subjects- of Science, Maths, and Computing with exceptional preparation in Key Stage 1, Key Stage 2, and Key Stage 3. We have dedicated and talented professionals with many years of teaching experience that provide high-quality and user-focused teaching sessions as per their school curriculum.</p>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-8 col-sm-12 col-lg-8 col-xl-8 col-xs-12">
                                    <p class="textjustify block_font" >In addition to this, the private tutors on our platform understand the significance of your child’s educational curriculum in an efficient manner. We are aware that the student is embarking on a path that would not only provide education building blocks for their success but would also aid in shaping the individual they become. The curriculum plays a significant role as the central basis for achieving the goals devised by the teacher, the curriculum, and aids in conducting the learning and teaching activities. In fact, the curriculum helps a student to develop lessons that determine the objectives as well as the teaching methods, subject matter, and evaluation processes. However, along the educational path, the students and parents usually face challenges and triumphs in their personal and academic lives.</p>
                                </div>
                                <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 text-center">
                                    <img src={at_educationCap} alt="At Education Capsule" style={{ width: 290, marginTop: '-40px' }} />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
                                    <p class="textjustify block_font mt-4" >Thus, at Education Capsule, the educators and parents of the child would be able to supplement the curriculum with an extensive range of lesson plans, teaching tips, and worksheets, helpful in attaining high grades. With an emphasis on Math, computing, and science, our topic explanations and conceptual videos of the curriculum help a student to develop an understanding of each topic effectively. We recognize the significance of enabling the students to look beyond the curriculum in order to expand their understanding and knowledge by satisfying intellectual inquisitiveness. Our broader and more informative program aims to offer the children opportunities to curtail themselves academically for attaining their personal best. Our expert tutorship team aligns with the student’s needs and preferences from subject and levels, ranging down to exam board and personality compatibility. The tutors are always available to listen, answer queries, and offer tailored support to each student in an efficient manner. We are available 24×7. The sessions can be booked at any time of the day, as per the availability of the learners.</p>
                                </div>
                            </div>
                        </div>

                        <div className="container mt-5">
                            <div className="col-xl-12">
                                <h2 className="largefont">What do We Teach?</h2>
                                <p className="subheading" >We assess your child's abilities and style of learning and provide <br></br> personalized and adaptive one-to-one sessions for </p>
                            </div>
                            <div className="row " >
                                <div className="col-xl-4 col-md-4">
                                    <div className="cardnew2">
                                        <div className="imagecard">
                                            <img src={deliver_first} alt="math" className="sliderimage subimg" />
                                        </div>
                                        <div className="card-bodynew">
                                            <h5 className="card-title">AQA</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4">
                                    <div className="cardnew2">
                                        <div className="imagecard">
                                            <img src={deliver_first} alt="seven" className="subimg sliderimage" />
                                        </div>
                                        <div className="card-bodynew">
                                            <h5 className="card-title">Edexcel</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4">
                                    <div className="cardnew2">
                                        <div className="imagecard">
                                            <img src={deliver_first} alt="eleven" className="subimg sliderimage" />
                                        </div>
                                        <div className="card-bodynew">
                                            <h5 className="card-title">OCR</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4 mt-5">
                                    <div className="cardnew2">
                                        <div className="imagecard">
                                            <img src={deliver_first} alt="thirteen" className="subimg sliderimage" />
                                        </div>
                                        <div className="card-bodynew">
                                            <h5 className="card-title">WJEC</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4 mt-5">
                                    <div className="cardnew2">
                                        <div className="imagecard">
                                            <img src={deliver_first} alt="thirteen" className="subimg sliderimage" />
                                        </div>
                                        <div className="card-bodynew">
                                            <h5 className="card-title">CCEA</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4 mt-5">
                                    <div className="cardnew2">
                                        <div className="imagecard">
                                            <img src={deliver_first} alt="thirteen" className="subimg sliderimage" />
                                        </div>
                                        <div className="card-bodynew">
                                            <h5 className="card-title">Pearson</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-xl-12">
                                    <h2 className="deliverfont ">What We will Deliver</h2>
                                    <p className="subheading ">There is a Tailor - Made Solution for every Child</p>
                                </div>
                            </div>
                            <div className="row text-center">
                                <div className="col-lg-4 col-sm-12 col-md-4">
                                    <img src={deliver_first} className="subimg" alt="deliver_image" />
                                    <h3 style={{ fontSize: 30 }}>Practice Questions</h3>
                                    <p className="text-center deliversubtext block_font" >At the end of each topic, there are a series of questions ranging from the easy to the hard level. Students can self-practice and check their progress.
                                    </p>
                                </div>
                                <div className="col-lg-4 col-sm-12 col-md-4">
                                    <img src={deliver_second} className="subimg" alt="deliver_image" />
                                    <h3 style={{ fontSize: 30 }}>Conceptual Teaching</h3>
                                    <p className="text-center deliversubtext block_font">We promote conceptual clarity instead of the rote method of learning. Students are encouraged to learn the concept behind every topic. </p>
                                </div>
                                <div className="col-lg-4 col-sm-12 col-md-4">
                                    <img src={deliver_third} className="subimg" alt="deliver_image" />
                                    <h3 style={{ fontSize: 30 }}>Video-Based Lessons</h3>
                                    <p className="text-center deliversubtext block_font" >We provide video-based instructions on every topic that particularly teaches a concept. Our learning resources are interactive and easy to understand.
                                    </p>
                                </div>
                            </div>

                            <div className="row text-center">
                                <div className="col-md-2 col-lg-2"></div>
                                <div className="col-lg-4 col-sm-12 col-md-4">
                                    <img src={deliver_forth} className="subimg" alt="deliver_image" />
                                    <h3 style={{ fontSize: 30 }}>Tailor-made<br></br>Learning journey</h3>
                                    <p className="text-center deliversubtext block_font" >We identify the individual student requirements and meet them, which raises morale and encourages them. The method that causes the learner to struggle is replaced by a simple way for the student.
                                    </p>
                                </div>
                                <div className="col-lg-4 col-sm-12 col-md-4">
                                    <img src={deliver_fifth} className="subimg" alt="deliver_image" />
                                    <h3 style={{ fontSize: 30 }}>Online<br></br>One-To-One Session</h3>
                                    <p className="text-center deliversubtext block_font" >We provide high-quality, one-on-one online coaching according to the specific requirements and learning style at fair costs. Students receive 100 % learning satisfaction and happiness.
                                    </p>
                                </div>
                                <div className="col-md-2 col-lg-2"></div>

                            </div>
                        </div>
                    </section>
                </div >
            </>
        )
    }
}

export default CurriculamPage
