import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import * as Constant from '../Constant'
import Skeleton from 'react-loading-skeleton';
import './scroll.css'

function CourseDetails() {
    const [coursename, setCourseName] = useState();
    const [coursetitle, setCourseTitle] = useState();
    const [price, setPrice] = useState();
    const [hours, setHours] = useState();
    const [image, setImage] = useState();
    const [description, setDescription] = useState();
    const [isLoading, setisLoading] = useState(true);
    const [loadingCourse, setloadingCourse] = useState(true);

    const { slug } = useParams()
    console.log(slug)

    useEffect(() => {
        getCourseDetails();
    },[])
    
    const getCourseDetails = async () => {
        setisLoading(true);
       
        await fetch(Constant.getCourseDetails + '/' + slug , {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                console.log(data)
                setloadingCourse(false);
                setisLoading(false);
                setCourseName(data.data.course_name)
                setCourseTitle(data.data.course_title)
                setPrice(data.data.price)
                setHours(data.data.hours)
                setImage(data.data.image)
                setDescription(data.data.description)
            })
    }
    

    return (
        <>
            <section id="chapterrr" className="sptb">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 mx-auto">
                            <div className="chapter-headings">
                                {
                                    loadingCourse === true ? <div className="text-center mt-4 mb-4"> <Skeleton height={50} width={200} count={1} /></div>
                                        :
                                        <>
                                            {
                                                coursename !== '' ? <div className="text-center mt-4 mb-4"><div style={{ display: 'flex', justifyContent: 'center' }}><h1>{coursename}</h1></div></div > : ''
                                            }
                                        </>
                                }
                                {
                                    isLoading === true ? <div className="text-center mt-4 mb-4"> <Skeleton height={40} width={400} count={1} /></div> : 
                                    <>
                                    <div className="card border" >
                                        <div className="card-body paddingtop">
                                            <section style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                                    <h3 style={{ fontSize: 30 }} className="text-center mb-4">{coursetitle}</h3>
                                                    <img
                                                        style={{height:250,width:250}}
                                                        src={image}
                                                        alt="Courses Image"
                                                        />
                                                    <h4 className="mt-4">{price}</h4>
                                                    <h4>{hours}</h4>
                                                    <div dangerouslySetInnerHTML={{ __html: description }} />
                                                </div>
                                            </section>

                                        </div>
                                           
                                    </div> 

                                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px' }}>
                                        <div className="lending-btn">
                                            <NavLink to="/contact-us" >Enroll Now</NavLink>
                                        </div>
                                    </div>
                                    </>
                                    
                                }
                              
                            </div>
                        </div>

                     
                    </div>
                  
                </div>

            </section >
        </>

    )
}

export default CourseDetails;