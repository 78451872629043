import React from "react";
import sen from '../assets/img/services/new/sen.png'

class HomeSchooling extends React.Component {

    render() {
        return (
            <>
                <div style={{ marginTop: '-1px' }}>
                    <section className="service-page-new" >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 col-sm-12 col-lg-7 col-xl-7 col-xs-12">
                                    <h1 className="banner-text1" style={{ textAlign: 'left', marginTop: 55 }}>Empowering Home Education</h1>
                                    <p style={{ color: '#fff', textAlign: 'justify', fontSize: 19 }}>At Education Capsule, we provide comprehensive homeschooling support designed to complement and enhance your home education efforts. Dive into a world where educational freedom meets structured, professional guidance.
                                    </p>
                                    {/* <a href="/book-a-tutor" className="lending-btn" >Book free Lesson Now</a> */}

                                </div>
                                <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12">
                                    <img src={sen} alt="sen" className="p-2" />
                                </div>

                            </div>
                        </div>
                    </section >
                </div>

                <div className="container one-to-one">
                    <div className="row">
                        <h2 className="text-center">Why Choose Our Homeschooling Services?</h2>

                        <h3>Customized Curriculum</h3>
                        <p>We understand that every child is unique. Our homeschooling programs are flexible, allowing us to create a curriculum that adapts to the specific needs, interests, and learning pace of your child.</p>

                        <h3>Support When You Need It</h3>
                        <p>From providing expert advice to offering resources and materials, our team is here to support your homeschooling journey every step of the way.</p>

                        <h3>A Community of Learners</h3>
                        <p>Join a thriving community of homeschoolers where families share resources, experiences, and encouragement. Participate in scheduled group sessions and community events to enrich your homeschooling experience.</p>

                        <h3>Our Educators</h3>
                        Expert Guidance at Home
                        <p>Our educators are experienced in a variety of educational methodologies and are passionate about creating engaging and effective learning experiences. They're here to support both students and parents with expertise and empathy.</p>

                        <h3>Tailored Learning Plans</h3>
                        <p>Every student receives a personalized learning plan that targets their specific educational needs, promoting better outcomes and more enjoyable learning experiences.</p>

                        <h2 className="mt-4 text-center">How We Operate</h2>
                        <h3>Initial Consultation</h3>
                        <p>Start with a comprehensive assessment that helps us understand your educational goals and the learning style of your child.</p>

                        <h3>Building Your Curriculum</h3>
                        <p>We collaborate with you to build a tailored curriculum that meets your educational standards and fits your child’s learning needs.</p>

                        <h3>Ongoing Support and Adjustments</h3>
                        <p>Our commitment doesn’t end with curriculum delivery. We provide continuous support and make adjustments as needed to ensure that your homeschooling experience is successful.</p>

                        <h2 className="mt-4 text-center">Success in Homeschooling</h2>
                        <h3>Family Testimonials</h3>
                        <p>Hear from families who have thrived with our homeschooling support, seeing remarkable growth and achievement in their educational journeys.</p>

                        <h3>Achievements Unlocked</h3>
                        <p>Celebrate the successes and milestones of our homeschooling community through stories and updates.</p>

                        <h2 className="mt-4 text-center">Start Your Homeschooling Journey With Us</h2>
                        <h3>Get Connected</h3>
                        <p>Learn how to begin your personalized homeschooling experience with us. Our team is ready to guide you every step of the way.</p>

                        <h3>Reach Out</h3>
                        <p>Have questions? Contact our dedicated support team for more information on how we can help enhance your homeschooling efforts.</p>

                        <h2 className="mt-4 text-center">Discover the Potential of Home Education</h2>
                        <h3>Join Our Community Today</h3>
                        <p>Embrace the flexibility and effectiveness of our homeschooling support services. Register now to transform your educational approach and achieve outstanding results.</p>

                    </div>
                </div>
                </>
            )
    }
}
export default HomeSchooling;